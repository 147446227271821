<template>
  <div>
    <card-notification v-for="(notif, idx) in notificationsList || []" :key="idx" :data="notif" />
    <ion-infinite-scroll
      @ionInfinite="$emit('loadMore', $event)"
      threshold="100px"
      id="infinite-scroll"
      :disabled="isDisabledLoadMore || notificationsList?.length <= 0"
    >
      <ion-infinite-scroll-content loading-spinner="bubbles" loading-text="Loading ...">
      </ion-infinite-scroll-content>
    </ion-infinite-scroll>
  </div>
</template>
<script>
import CardNotification from '@/modules/shared/components/notifications/CardNotification.vue';

import { defineComponent } from 'vue';
export default defineComponent({
  name: 'AllNotifications',
  emits: ['loadMore'],
  components: {
    CardNotification
  },
  props: {
    notificationsList: {
      type: Array,
      default: () => []
    },
    isMerchantUser: {
      type: Boolean,
      default: false
    },
    isDisabledLoadMore: {
      type: Boolean,
      default: false
    }
  }
});
</script>
