<template>
  <div>
    <ion-list v-for="notif in notifOptions" :key="notif.name">
      <ion-item :button="true" class="ion-align-items-center" @click="notif.handler">
        <div class="mr-2" slot="start">
          <ion-img :src="notif.icon" />
        </div>
        <ion-label class="fw-600 fs-2">
          <ion-text> {{ notif.label }} </ion-text><br />
          <ion-note color="medium" class="fw-500">
            {{ notif?.latestMessage }}
          </ion-note>
        </ion-label>
        <div class="d-flex">
          <ion-note v-if="notif.badgeNumber" slot="end" class="fs-1 ml-3 badge">{{
            notif.badgeNumber
          }}</ion-note>
          <ion-icon :icon="chevronForwardOutline" color="primary" class="i-medium" slot="end"></ion-icon>
        </div>
      </ion-item>
    </ion-list>
  </div>
</template>
<script>
import { chevronForwardOutline } from 'ionicons/icons';
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'CategoryNotif',
  props: {
    notifOptions: {
      type: Object,
      default: () => {}
    }
  },
  setup() {
    return {
      chevronForwardOutline
    };
  }
});
</script>
<style lang="scss" scoped>
.badge {
  background-color: var(--ion-color-danger);
  border-radius: 0.3rem;
  color: #fff;
  font-weight: 600;
  margin: auto;
  padding: 3px 5px;
}
.i-medium {
  font-size: 24px;
}
</style>
